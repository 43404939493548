import { useTheme } from 'styled-components';

import { useLangContext } from 'data/contexts';
import { useGetSessionUser } from 'data/modules/auth';

import { CloseAlert } from 'presentation/components/base/CloseAlert';
import { Loader } from 'presentation/components/global/Loader';
import { Navbar } from 'presentation/components/global/Navbar';
import { Navbar as OldNavbar } from 'presentation/components/global/OldNavbar';
import { SidebarContainer } from 'presentation/components/global/Sidebar';

import { useDefaultLayout } from './useDefaultLayout';

import { type IDefaultLayout } from './DefaultLayout.types';

import {
  ConfirmEmailButton,
  Content,
  ContentContainer,
  DefaultContainer
} from './DefaultLayout.styles';

export function DefaultLayout({
  forceSidebarCollapse,
  pageNameAndBreadcrumbs,
  children,
  onScroll
}: IDefaultLayout): JSX.Element {
  const {
    setSidebarIsCollapsed,
    sidebarIsCollapsed,
    handleConfirmEmailButtonClick,
    isSendingEmailConfirmation
  } = useDefaultLayout(forceSidebarCollapse);

  const { user } = useGetSessionUser();

  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const theme = useTheme();

  return (
    <>
      <Loader isLoading={isSendingEmailConfirmation} />
      <DefaultContainer>
        <SidebarContainer
          onSetIsCollapsed={setSidebarIsCollapsed}
          isCollapsed={sidebarIsCollapsed}
        />

        <ContentContainer $isCollapsed={sidebarIsCollapsed}>
          {!pageNameAndBreadcrumbs || pageNameAndBreadcrumbs?.isClassicMode ? (
            <OldNavbar
              isTourButtonVisible={false}
              onToggleSidebar={() => {
                setSidebarIsCollapsed(!sidebarIsCollapsed);
              }}
            />
          ) : (
            <Navbar
              onToggleSidebar={() => {
                setSidebarIsCollapsed(!sidebarIsCollapsed);
              }}
              pageNameAndBreadcrumbs={pageNameAndBreadcrumbs}
            />
          )}

          {/* Alerta de email do layout desktop */}
          {user && !user.confirmed && (
            <CloseAlert color={theme.colors.tango}>
              <p>
                {lang.navbar.components.confirm_account_1[currentLangKey] +
                  user.email +
                  lang.navbar.components.confirm_account_2[currentLangKey]}

                <ConfirmEmailButton onClick={handleConfirmEmailButtonClick}>
                  {lang.navbar.components.confirm_account_3[currentLangKey]}
                </ConfirmEmailButton>

                {lang.navbar.components.confirm_account_4[currentLangKey]}
              </p>
            </CloseAlert>
          )}

          <Content onScroll={onScroll}>{children}</Content>
        </ContentContainer>
      </DefaultContainer>
    </>
  );
}
