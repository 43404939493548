import { create } from 'zustand';

import { TravelCheckout } from 'shared/utils/travels';

import {
  type ITravelCheckoutContext,
  type ITravelCheckoutStates,
  TravelCheckoutSteps
} from './useTravelCheckoutContext.types';

export const initialStates: ITravelCheckoutStates = {
  currentStep: TravelCheckoutSteps.BOOKINGS,
  travelToBeCreated: {
    description: undefined,
    observation: undefined
  },
  travelToBeSelected: undefined,
  itemsJustificationRecord: {},
  outOfPolicyBookingsIds: [],
  isContinueButtonDisabled: false,
  retryQueryKey: null
};

export const useTravelCheckoutContext = create<ITravelCheckoutContext>()(
  (set, get) => ({
    ...initialStates,
    handleChangeCurrentStep: newStep => {
      set({
        currentStep: newStep
      });
    },
    handleClearContext: () => {
      set(initialStates);
    },
    handleChangeTravelToBeCreated: payload => {
      set(state => ({
        travelToBeCreated: {
          ...state.travelToBeCreated,
          ...payload
        },
        travelToBeSelected: undefined
      }));
    },
    handleChangeTravelToBeSelected: payload => {
      set({
        travelToBeSelected: payload,
        travelToBeCreated: undefined
      });
    },
    handleChangeJustification: (bookingId, justification) => {
      set(state => ({
        itemsJustificationRecord: {
          ...state.itemsJustificationRecord,
          [bookingId]: {
            justification,
            isValid: TravelCheckout.validateField(justification)
          }
        }
      }));

      const isMissingJustification = get().outOfPolicyBookingsIds.some(
        itemId => !get().itemsJustificationRecord[itemId]?.isValid
      );

      set({
        isContinueButtonDisabled: isMissingJustification
      });
    },
    handleChangeOutOfPolicyBookingsIds: newValue => {
      set({
        outOfPolicyBookingsIds: newValue
      });

      const isMissingJustification = newValue.some(
        itemId => !get().itemsJustificationRecord[itemId]?.isValid
      );

      set({
        isContinueButtonDisabled: isMissingJustification
      });
    },
    handleSetIsContinueButtonDisabled: newState => {
      set({
        isContinueButtonDisabled: newState
      });
    },
    setRetryQueryKey: retryQueryKey => {
      set({ retryQueryKey });
    }
  })
);
