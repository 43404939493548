import { type ICardsUser, type IPersistenceCardsUser } from 'data/modules/auth';

class GetAuthCardsUserMapper {
  toDomain(persistenceCardsUser: IPersistenceCardsUser): ICardsUser {
    return {
      id: persistenceCardsUser.id,
      type: persistenceCardsUser.type,
      name: persistenceCardsUser.name,
      mustHaveAccount: persistenceCardsUser.must_have_account,
      hasAccount: persistenceCardsUser.has_account,
      manager: persistenceCardsUser.manager,
      onboardingCompletedAt: persistenceCardsUser.onboarding_completed_at,
      sharedAccounts: persistenceCardsUser.shared_accounts,
      parameters: persistenceCardsUser.parameters,
      accountHolder: {
        kycStatus: persistenceCardsUser.account_holder?.kyc_status,
        kycUpdatedAt: persistenceCardsUser.account_holder?.kyc_updated_at
      },
      isOnboardingFinished:
        !!persistenceCardsUser.onboarding_completed_at &&
        persistenceCardsUser.account_holder?.kyc_status === 'APPROVED',
      isManagerWithoutAccount:
        !persistenceCardsUser.must_have_account && persistenceCardsUser.manager,
      isCardGroupManager: persistenceCardsUser.card_group_manager,
      isCardGroupManagerWithoutAccount:
        persistenceCardsUser.card_group_manager &&
        persistenceCardsUser.account_holder === null,
      accountAggregationId: persistenceCardsUser.account_aggregation_id
    };
  }
}

export default new GetAuthCardsUserMapper();
