import { type QueryKey } from '@tanstack/react-query';

import { type ITravel } from 'data/modules/travel/myTravels';

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum TravelCheckoutSteps {
  BOOKINGS = 1,
  TRIP,
  JUSTIFICATION,
  REVIEWANDSUBMIT
}

export interface ITravelToBeCreated {
  description?: string;
  observation?: string;
}

export interface ITravelToBeSelected extends ITravel {}

export type ItemsJustificationRecordType = Record<
  string,
  {
    justification: string;
    isValid: boolean;
  }
>;

export interface ITravelCheckoutStates {
  currentStep: TravelCheckoutSteps;
  travelToBeCreated?: ITravelToBeCreated;
  travelToBeSelected?: ITravelToBeSelected;
  itemsJustificationRecord: ItemsJustificationRecordType;
  outOfPolicyBookingsIds: string[];
  isContinueButtonDisabled: boolean;
  retryQueryKey: QueryKey | null;
}

export interface ITravelCheckoutContext extends ITravelCheckoutStates {
  handleChangeCurrentStep: (newStep: TravelCheckoutSteps) => void;
  handleClearContext: () => void;
  handleChangeTravelToBeCreated: (payload: ITravelToBeCreated) => void;
  handleChangeTravelToBeSelected: (payload: ITravelToBeSelected) => void;
  handleChangeJustification: (bookingId: string, justification: string) => void;
  handleChangeOutOfPolicyBookingsIds: (newValue: string[]) => void;
  handleSetIsContinueButtonDisabled: (newState: boolean) => void;
  setRetryQueryKey: (retryQueryKey: QueryKey | null) => void;
}
